import React, { memo } from 'react';
import PropTypes from 'prop-types';

import AddAllItemsErrorModal from 'components/AddAllItemsErrorModal';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';

import styles from './AddAllItemsButton.scss';

const AddAllItemsButton = memo(
  ({ addAllItems, display, itemCount, label, onTogglePreviousOrderNotesModal }) => {
    if (!display || itemCount === 0) return null;

    return (
      <>
        <div className="row">
          <div
            className={`${styles.addAllItemsButton} col-xs-12of12 col-md-offset-8of12 col-md-4of12 col-lg-offset-9of12 col-lg-3of12`}
          >
            <Button
              data-test="addAllButton"
              label={label || `Add all ${itemCount} item${itemCount === 1 ? '' : 's'} to trolley`}
              onClick={() => {
                if (onTogglePreviousOrderNotesModal) {
                  onTogglePreviousOrderNotesModal();
                } else {
                  addAllItems();
                }
              }}
              theme="secondary"
              width="full"
            />
          </div>
        </div>
        <AddAllItemsErrorModal />
      </>
    );
  },
);

AddAllItemsButton.displayName = 'AddAllItems';

AddAllItemsButton.propTypes = {
  addAllItems: PropTypes.func.isRequired,
  display: PropTypes.bool,
  itemCount: PropTypes.number,
  label: PropTypes.string,
  onTogglePreviousOrderNotesModal: PropTypes.func,
};

AddAllItemsButton.defaultProps = {
  display: true,
  itemCount: undefined,
  label: undefined,
  onTogglePreviousOrderNotesModal: undefined,
};

export default AddAllItemsButton;
